import React from "react"
import Modal from "react-modal"
import * as styles from "../../styles/components/vision-modal.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

const VisionModal = (props) => (
  <Modal
    isOpen={props.open}
    onRequestClose={props.close}
    className={styles.visionModal}
    overlayClassName={styles.visionOverlay}
  >
    <div>
      <button onClick={props.close}>
        <img src="/images/ico-close.svg" width="24" alt="x" />
      </button>
      { props.themes.map((theme, id) => {
        const title = theme.title === null  ? props.defaultTheme : theme.title
        return <div className={styles.theme}>
            <h2 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
            <h3 dangerouslySetInnerHTML={{ __html: specialCharacterReplace(props.companyName) }} />
            <table className={styles.archiveTable}> 
            {
              theme.visions.map((vision, id) => {
                return <tr>
                    <td>{ vision.date }{ (id === 0 ) ? <span className={styles.recently}>最新</span> : '' }</td>
                    <td><a href={ vision.pdf } rel="noreferrer noopener" className={styles.archive} target="_blank">事業戦略ビジョン</a></td>
                  </tr>
              })
            }
            </table>
            { theme.notice && <p className={styles.notice}>{ theme.notice }</p> }
            <p className={styles.updateDate}>更新日 : { theme.updatedDate }</p>
          </div>
        }) 
      }
    </div>
  </Modal>
)
export default VisionModal