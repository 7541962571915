import React from "react"
import * as styles from "../../styles/page/second.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'
import TopFeature from "../../components/parts/top/feature"
import ShareButtons from "../../components/parts/share-button"

/* 特定のプロジェクト情報を取得 */
export const getCurrentProject = (slug, type = '') => {
  const { allProjects } = GetProject()
  const project = allProjects.filter(item => {
    return `/project/${item.node.slug}${type}` === slug
  })
  return project
}

/* メインイメージ */
export const MainImage = (props) => {
  const { slug, current, alt } = props
  const replaceCheck = (current === 'overview') ? false : current
  return (
    <img className={styles.mainImage} src={`/images${ (replaceCheck) ? slug.replace(current+'/', '') : slug}main.png`} alt={alt} />
  )
}

/* タブ */
export const ProjectTab = (props) => {
  const { current, slug } = props
  const path = (current === 'overview') ? './' : '../'
  const project = getCurrentProject(slug, (current === 'overview') ? '/' : `/${current}/`)
  const { summary, scheme, progress } = project[0].node

  return (
    <div className={styles.articleTabWrapper}>
      <ul className={styles.articleTab}>
        <li className={current === 'overview' ? styles.active : ''}><a href={path} data-label="overview">概要</a></li>
        {
          (summary) ? 
            <li className={current === 'summary' ? styles.active : ''}><a href={`${path}summary/`} data-label="summary">基本情報</a></li> : ''
        }
        {
          (scheme) ?
            <li className={current === 'scheme' ? styles.active : ''}><a href={`${path}scheme/`} data-label="scheme"><small>実施体制<br />事業戦略ビジョン</small></a></li> : ''
        }
        {
          (progress) ?
            <li className={current === 'progress' ? styles.active : ''}><a href={`${path}progress/`} data-label="progress"><small>進捗情報</small></a></li> : ''
        }
      </ul>
      <ShareButtons style={styles.articleSns} url={`https://${process.env.HOST_NAME}${slug}`} />
    </div>
  )
}

/* 右袖メニュー全体を出力 */
export const ProjectSideMenu = (props) => {
  const { slug, type, current } = props
  return (
    <aside className={styles.articleContentsRight}>
      <RelatedInformation slug={slug} type={type} />
      <RelatedResources slug={slug} type={type} />
      <Others slug={slug} type={type} current={current} />
    </aside>
  )
}

/* 右袖メニュー 関連情報 */
export const RelatedInformation = (props) => {
  const { slug, type } = props
  const { allPublicContribution } = GetProject()
  const relatedInformation = allPublicContribution.filter(item => {
    return `/project/${item.node.publicContributionId}${type}` === slug
  })
  return (
    <>
      <div className={`${styles.pageTitle} pb-1`}>
        <h3 className={styles.pageTitleSmallHeading}>
          <span className={styles.pageTitleLine}>関連情報</span>
        </h3>
      </div>
      <div className={styles.relatedLink}>
        <ul>
          {
            relatedInformation[0].node.newsRelease.map((item, id) => {
              const { title, link } = item
              return <li key={`relatedlink-${id}`} className={styles.relatedLinkInnerList}>
                <a className={`${styles.linkText} ${/.pdf$/.test(link) ? styles.pdf : styles.outbound}`} href={link} target="_blank" rel="noreferrer noopener">
                  {title}
                </a></li>
              })
          }
        </ul>
      </div>
    </>
  )
}

/* 右袖メニュー 関連資料 */
export const RelatedResources = (props) => {
  const { slug, type } = props
  const relatedResource = getCurrentProject(slug, type)

  return (
    <>
      <div className={`${styles.pageTitle} pb-1`}>
      <h3 className={styles.pageTitleSmallHeading}>
        <span className={styles.pageTitleLine}>関連資料</span>
      </h3>
      </div>
      <div className={styles.relatedLink}>
      <ul>
        {
          relatedResource[0].node.relatedResource.map((item, key) => {
            return <li key={`relatedlink-${key}`} className={styles.relatedLinkInnerList}><a className={`${styles.linkText} ${styles.pdf}`} href={item.link} target="_blank" rel="noreferrer noopener">{item.title}</a></li>
          })
        }
      </ul>
      </div>
    </>
  )
}

/* 右袖メニュー その他 */
export const Others = (props) => {
  const { current, slug } = props
  const project = getCurrentProject(slug, (current === 'overview') ? '/' : `/${current}/`)
  const { features, toBusiness } = GetProject()
  return (
    <>
      <div className={styles.pageTitle}>
        <h3 className={styles.pageTitleSmallHeading}>
          <span className={styles.pageTitleLine}>特集記事等</span>
        </h3>
      </div>
      
      <div className={styles.featureAside}>
        {features.map( (item, id) => {
          return project[0].node.relatedArticle.includes(item.node.frontmatter.slug) ? <TopFeature key={`top-feature-${id}`} index={id} data={item.node.frontmatter} summaryHide={true} /> : <></>
        })}
        {toBusiness.map( (item, id) => {
          return <TopFeature key={`top-feature-fixed-${id}`} index={id} data={item.node.frontmatter} summaryHide={true} />
        })}
      </div>
      {/*<p className={styles.allLists}><Link className={styles.allListsBullet} to="/article/">全てを見る</Link></p>*/}

      <div className="text-center mb-3">
        <a href="https://www.nedo.go.jp/library/ZZNA_100091.html" target="_blank" rel="noreferrer noopener">
          <StaticImage src={'../../images/bnr_tsc_report.png'} alt="TSC Foresight TSCトレンド COP26に向けたカーボンニュートラルに関する海外主要国（米・中・EU・英）の動向 TSCレポート" placeholder="blurred" />
          <div className={styles.tscTrendTitle}>TSCレポート</div>
        </a>
      </div>

      <div className="text-center">
        <a href="https://www.nedo.go.jp/content/100955752.pdf" target="_blank" rel="noopener noreferrer">
          <StaticImage src={'../../images/bnr-pamphlet.png'} alt="グリーンイノベーション基金事業パンフレット" placeholder="blurred" />
          <div className={styles.pamphletTitle}>グリーンイノベーション基金事業パンフレット</div>
        </a>
      </div>

    </>
  )
}

const GetProject = () => {
  const { publicContribution, projects, feature, to_business } = useStaticQuery(graphql`
    query getProjectQuery {
      publicContribution: allMicrocmsPublicContribution {
        edges {
          node {
            newsRelease {
              title
              link
            }
            publicContributionId
          }
        }
      }
      projects: allProjectsJson {
        edges {
          node {
            slug
            title
            description
            mainImage
            relatedResource {
              link
              title
            }
            relatedArticle
            progress
            scheme
            summary
          }
        }
      }
      feature: allMarkdownRemark(
        filter: {fields: {slug: {regex: "/article/"}}}
        sort: { fields: [frontmatter___order], order: ASC }
        limit: 100
      ) {
        edges {
          node {
            frontmatter {
              title
              order
              mainImage 
              slug
            }
          }
        }
      }
      to_business: allMarkdownRemark(
        filter: {fields: {slug: {eq: "/article/to-business/"}}}
      ) {
        edges {
          node {
            frontmatter {
              title
              mainImage
              slug
            }
          }
        }
      }
    }`
  )
  const allPublicContribution = publicContribution.edges
  const allProjects = projects.edges
  const features = feature.edges
  const toBusiness = to_business.edges

  return {
    allPublicContribution,
    allProjects,
    features,
    toBusiness
  }
}