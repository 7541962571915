import React, { useState } from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// プロジェクトページコンポーネント
import { getCurrentProject, MainImage, ProjectTab, ProjectSideMenu } from "../../components/parts/project"

import VisionModal from "../../components/parts/vision-modal"

const ProjectArticlePage = ({ data }) => {
  const pageType = 'scheme'
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const {
    slug,
    title,
    system,
    systemNotApplicableAd,
    systemNotApplicableAlone,
  } = frontmatter
  const { description } = getCurrentProject(slug)

  const [modalOpen, setModal] = useState(false);
  const openModal = (e) => {
    e.preventDefault();
    setModal(parseInt(e.currentTarget.getAttribute('data-num')))
  }
  const closeModal = (e) => {
    e.preventDefault();
    setModal(false)
  }
  let modalId = 0

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        ogImage={`https://${process.env.HOST_NAME}/images${ slug.replace(pageType+'/', '') }og.png`}
        path={slug}
      />

      <BreadCrumb
        parentLink="/project/"
        parentTitle="プロジェクト情報"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
      </div>

      <main className={styles.articleContents}>
        <article className={styles.articleContentsLeft}>
          <MainImage slug={slug} current={pageType} alt={title} />
          <ProjectTab current={pageType} slug={slug} />

          { system === null ? '' :
          <div id="projectSystem" className={`${styles.tabPane} ${styles.activePane}`}>
            <div className={styles.articleInner}>
              <h2>プロジェクト実施者</h2>
              { system.map((phase, id) => {
                  return <>
                    {phase.title !== null ? 
                      <h3 key={`system-${id}`} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(phase.title) }} />
                      : ''
                    }
                    { phase.outline === null ? '' :
                        <ul>
                          { phase.outline.map((item, key) => {
                            return <li key={`system-${id}-${key}`} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(item) }} />
                          })}
                        </ul>
                    }
                    <table className={styles.projectSystemTable}>
                      <thead>
                        <tr>
                          <th>テーマ</th>
                          <th>事業者</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          phase.list.map((list, id) => {
                            modalId++
                            return <tr>
                              <td dangerouslySetInnerHTML={{ __html: specialCharacterReplace(list.theme) }} />
                              <td><ul>{ 
                                list.companies.map(company => {
                                  modalId++
                                  return <li key={`company-${modalId}-${id}`}>
                                      <div className={styles.companyName}>
                                        {company.secretary ? <span className={styles.secretary}>幹事</span> : ''}
                                        <span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(company.name) }} />
                                      </div>
                                      { (company.notApplicable) ? 
                                        <div>
                                          <span className={styles.notApplicable}>{company.notApplicable}</span>
                                        </div> : company.themes === null ? '' :
                                        <div>
                                          <a href="/#" onClick={openModal} data-num={modalId} className={styles.archive}>事業戦略ビジョン</a>
                                          <VisionModal
                                              open={(modalOpen === modalId)}
                                              close={closeModal}
                                              defaultTheme={list.theme}
                                              companyName={company.name}
                                              themes={company.themes}
                                            />
                                        </div>
                                      }
                                    </li>
                                })
                              }</ul></td>
                            </tr>
                          })
                        }
                      </tbody>
                    </table>
                    {phase.allVision === null ? '' :
                      <p className="text-right"><a href={phase.allVision} className={styles.visionDownload}>最新の事業戦略ビジョンをまとめてダウンロード</a></p>
                    }
                  </>
                })
              }
              <p className="pt-3">
                <small>
                  ※事業戦略ビジョン：本プロジェクトに参画する企業等の経営者がコミットメントを示すため、事業戦略や事業計画、研究開発計画、イノベーション推進体制などの詳細を明らかにした資料。
                  { (systemNotApplicableAlone) ? 
                    'なお、大学や公的研究機関等については原則公表の対象ではないが、単独実施の研究機関等についてはこの限りではない。' : 
                      (systemNotApplicableAd) ? 'なお、大学や公的研究機関等については原則公表の対象ではない。' : ''
                  }
                </small>
              </p>
            </div>
          </div>
          }
        </article>
        
        <ProjectSideMenu slug={slug} type={`/${pageType}/`} current={pageType} />
      </main>
    </Layout>
  )
}
export default ProjectArticlePage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        system {
          list {
            companies {
              name
              themes {
                title
                visions {
                  pdf
                  date
                }
                notice
                updatedDate
              }
              secretary
              notApplicable
            }
            theme
          }
          allVision
          title
          outline
        }
        systemNotApplicableAd
        systemNotApplicableAlone
      }
    }
  }
`